<template>
  <div>
    <el-card style="margin-bottom: 10px" shadow="never">
      <el-button type="primary" @click="save">保存</el-button>
      <el-button @click="edit">修改</el-button>
    </el-card>
    <el-form ref="form" :model="form" label-width="150px" :disabled="disabled">
      <el-form-item label="每天免费次数">
        <el-input type="Number" v-model.number="form.free"></el-input>
      </el-form-item>
      <el-form-item label="最大参与次数">
        <el-input type="Number" v-model.number="form.maxJoin"></el-input>
      </el-form-item>
      <el-form-item label="人民币奖励过期时间">
        <el-input type="Number" v-model.number="form.expireTime"></el-input>
      </el-form-item>
      <el-form-item label="一次消耗值">
        <el-input type="Number" v-model.number="form.cost"></el-input>
      </el-form-item>
      <el-form-item label="未完成限制">
        <el-input type="Number" v-model.number="form.unFinish"></el-input>
      </el-form-item>
      <el-form-item label="活动期限">
        <el-input v-model="form.dateDesc"></el-input>
      </el-form-item>
      <el-form-item label="新用户奖励">
        <el-input type="Number" v-model.number="form.newShare"></el-input>
      </el-form-item>
      <el-form-item label="老用户奖励">
        <el-input type="Number" v-model.number="form.oldShare"></el-input>
      </el-form-item>
      <el-form-item label="最大奖励次数">
        <el-input type="Number" v-model.number="form.validShare"></el-input>
      </el-form-item>
      <el-form-item label="重置时间">
        <el-input type="Number" v-model.number="form.resetInterval"></el-input>
      </el-form-item>
      <el-form-item label="获取次数">
        <el-button type="primary" @click="addObtains">添加一行</el-button>
        <el-table
          border
          :data="form.joinChance"
          style="width: 100%;margin:20px 0 "
          :row-class-name="tableRowClassName"
          @cell-click="tabClick"
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            width="80">
          </el-table-column>
          <el-table-column
            label="获取方式"
            prop="obtainType"
            align="center"
            width="200">
            <template slot-scope="scope">
              <el-select v-model="scope.row.obtainType" style="width:100%" placeholder="请选择获取方式">
                <el-option
                  v-for="item in obtains"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="state" label="状态">
            <template slot-scope="{row}">
              <el-switch v-model="row.state" active-text="生效" inactive-text="禁止" :active-value=1
                         :inactive-value=2>
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            label="兑换次数"
            prop="costCount"
            align="center"
          >
            <template slot="header">
              <span>兑换次数</span>
              <i class="el-icon-edit"></i>
            </template>
            <template slot-scope="scope">
            <span v-if="scope.row.index === tabClickIndex && tabClickProperty === 'costCount'">
              <el-input :disabled="disabled" type="Number" v-model.number="scope.row.costCount" maxlength="300" placeholder="请输入兑换次数" size="mini"
                        @blur="inputBlur" @keyup.enter.native="inputBlur"/>
            </span>
              <span :disabled="disabled" v-else>{{ scope.row.costCount }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="兑换值"
            prop="cost"
            align="center"
          >
            <template slot="header">
              <span>消耗值</span>
              <i class="el-icon-edit"></i>
            </template>
            <template slot-scope="scope">
            <span v-if="scope.row.index === tabClickIndex && tabClickProperty === 'cost'">
              <el-input :disabled="disabled" type="Number" v-model.number="scope.row.cost" maxlength="300" placeholder="请输入兑换值" size="mini"
                        @blur="inputBlur" @keyup.enter.native="inputBlur"/>
            </span>
              <span :disabled="disabled" v-else>{{ scope.row.cost }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="prop" label="操作">
            <template slot-scope="scope">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="delChance(scope.$index)"
              >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { GetActivityRequest, UpdateActivityRequest } from '../../../network/lottery'

export default {
  name: 'RedRulePane',
  data () {
    return {
      form: {
        free: 0,
        maxJoin: 0,
        newShare: 0,
        oldShare: 0,
        validShare: 0,
        resetInterval: 0,
        cost: 0,
        dateDesc: '',
        unFinish: 0,
        expireTime: 0,
        joinChance: [
        ]
      },
      obtains: [
        {
          label: '金币',
          value: 1
        }, {
          label: '看视频',
          value: 2
        }
      ],
      tabClickIndex: null, // 点击的单元格
      tabClickProperty: '', // 当前点击的列名,
      disabled: true
    }
  },
  mounted () {
    // 获取列表数据方法
    this.getRedActivity()
  },
  methods: {
    getRedActivity () {
      GetActivityRequest(2).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('获取抽奖规则失败', 'error')
        }
        // this.alertMessage('获取抽奖规则成功', 'success')
        console.log('activity res:', res.data)
        this.form = res.data.data
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
    },
    tabClick (row, column, cell, event) {
      switch (column.property) {
        case 'cost':
          this.tabClickIndex = row.index
          this.tabClickProperty = column.property
          break
        case 'costCount':
          this.tabClickIndex = row.index
          this.tabClickProperty = column.property
          break
        default:
          this.tabClickIndex = null
          this.tabClickProperty = ''
          break
      }
    },
    // 失去焦点初始化
    inputBlur (row, event, column) {
      this.tabClickIndex = null
      this.tabClickProperty = ''
    },
    addObtains () {
      this.form.joinChance.push(
        { obtainType: '', cost: 0, costCount: 0 }
      )
    },
    getObtainLabel (obtainType) {
      switch (obtainType) {
        case 1:
          return '金币'
        case 2:
          return '看视频'
      }
    },
    save () {
      this.disabled = true
      const rule = {
        free: this.form.free,
        maxJoin: this.form.maxJoin,
        newShare: this.form.newShare,
        oldShare: this.form.oldShare,
        validShare: this.form.validShare,
        resetInterval: this.form.resetInterval,
        cost: this.form.cost,
        dateDesc: this.form.dateDesc,
        expireTime: this.form.expireTime,
        unFinish: this.form.unFinish,
        name: '拆红包',
        desc: '拆红包',
        activityType: 2,
        ruleDesc: [],
        joinChance: []
      }
      for (const item of this.form.joinChance) {
        const rItem = {
          obtainType: parseInt(item.obtainType),
          cost: parseInt(item.cost),
          costCount: parseInt(item.costCount),
          state: parseInt(item.state)
        }
        rItem.label = this.getObtainLabel(rItem.obtainType)
        rule.joinChance.push(rItem)
      }
      console.log('----this.form:', rule)
      UpdateActivityRequest(rule).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改抽奖规则失败', 'error')
        }
        this.alertMessage('修改抽奖规则成功', 'success')
      })
    },
    edit () {
      this.disabled = false
    },
    delChance (index) {
      console.log('del chance row:', index)
      this.form.joinChance.splice(index, 1)
    }
  }
}
</script>

<style scoped>

</style>
