// 修改任务
import { request } from './request'

export function updateRedRuleRequest (info) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/red/rule',
    method: 'post',
    data: { ...info }
  })
}

export function getRedRuleRequest (ruleType) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/red/rule',
    params: {
      ruleType: ruleType
    },
    method: 'get'
  })
}
