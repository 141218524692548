<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>任务管理</template>
      <template v-slot:secondMenu>拆红包</template>
    </breadcrumb-nav>
    <el-card>
      <el-tabs>
        <el-tab-pane label="中奖记录">
          <red-record-pane></red-record-pane>
        </el-tab-pane>
        <el-tab-pane label="奖品设置">
          <red-reward-pane></red-reward-pane>
        </el-tab-pane>
        <el-tab-pane label="规则">
          <red-rule-pane></red-rule-pane>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <!--<el-card>
      <el-button
        type="primary"
        style="margin-bottom: 20px"
        @click="submit"
      >提交
      </el-button>
      <el-form ref="form" :model="form" label-width="150px">
        <el-form-item label="奖励最大值">
          <el-input type="Number" v-model.number="form.rewardMax"></el-input>
        </el-form-item>
        <el-form-item label="奖励最小值">
          <el-input type="Number" v-model.number="form.rewardVal"></el-input>
        </el-form-item>
        <el-form-item label="重置时间(分钟)">
          <el-input type="Number" v-model.number="form.resetInterval"></el-input>
        </el-form-item>
      </el-form>
    </el-card>-->
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
import RedRewardPane from './redChildComponents/RedRewardPane'
import RedRulePane from './redChildComponents/RedRulePane'
import RedRecordPane from './redChildComponents/RedRecordPane'
import { getRedRuleRequest, updateRedRuleRequest } from '../../network/red'
export default {
  name: 'Red',
  components: {
    BreadcrumbNav,
    RedRewardPane,
    RedRulePane,
    RedRecordPane
  },
  data () {
    return {
      form: {
        rewardMax: 0,
        rewardVal: 0,
        resetInterval: 0
      }
    }
  },
  mounted () {
    this.getRedRule()
  },
  methods: {
    submit () {
      updateRedRuleRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('更新红包规则失败', 'error')
        }
        this.alertMessage('修改红包规则成功', 'success')
      })
    },
    getRedRule () {
      getRedRuleRequest(1).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('获取红包规则失败', 'error')
        }
        console.log('redRule res:', res.data)
        this.form = res.data.data
      })
    }
  }
}
</script>

<style scoped>

</style>
